import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PaymentPricingFaqs from '../usercomponents/PaymentPricingFaqs';
import Pricingplans from '../usercomponents/Pricingplans';
import Meta from '../components/Meta';





const Pricing = () => {
    const [conversionRate, setConversionRate] = useState(1);

    useEffect(() => {
        // Example conversion rates
        const conversionRates = {
            Nigeria: 1,
            US: 0.0025, // 1 NGN to USD
            UK: 0.0018, // 1 NGN to GBP
            // Add more conversion rates as needed
        };
        const location = 'Nigeria'; // Set default location
        setConversionRate(conversionRates[location] || 1); // Default to Nigeria if location not found
    }, []);

    const convertAmount = (amount) => {
        return (amount * conversionRate).toFixed(2);
    };

    return (
        <div className="container-mx-auto-p-4">
            <Helmet>
                <title>Payment & Pricing | Lecturemeet </title>
                <Meta name="description" content="Explore our affordable pricing plans for individuals and organizations. Flexible payment options for all our professional courses and programs." />
<Meta name="keywords" content="Lecturemeet, payment, pricing, affordable plans, flexible payment options, professional courses, programs, education" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Lecturemeet",
                        "url": "https://lecturemeet.com/pricing",
                        "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24"
                        }
                    `}
                </script>

                <Meta property="og:title" content="Payment & Pricing | Lecturemeet" />
                <Meta property="og:description" content="Explore our affordable pricing plans for individuals and organizations. Flexible payment options for all our professional courses and programs." />
                <Meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
                <Meta property="og:url" content="https://lecturemeet.com/pricing" />
                <Meta name="twitter:card" content="summary_large_image" />
                <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />



        <link rel="canonical" href="https://lecturemeet.com/pricing" />


            </Helmet>

            <section className="pricing-hero-section">
                <div className="pricing-container">
                    <h1>Finding the right course ? </h1>
                    <p className='text-center'>Explore our learning beyond borders for professionals and organizations</p>
                </div>
            </section>

            <section>
                <table className="pricing-table">
                    <thead>
                        <tr>
                            <th>Programmes</th>
                            <th>Learning Models</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='tdp'>Corporate and banking</td>
                            <td>Corporate Governance, Risk Management,<br/> 
                                Anti-Money Laundering (AML/CFT), <br/>
                                Business Marketing and Strategy, <br/>
                                Taxation</td>
                            <td>₦{convertAmount(25000)}</td>
                        </tr>


                        <tr>
                            <td className='tdp'>Legal and Compliance</td>
                            <td>General Compliance, 
                                Anti-Money Laundering (AML/CFT),<br/> 
                                Due Process and Corporate Governance,<br/> 
                                Mergers and Acquisition, <br/>
                                Taxation and Compliance</td>
                            <td>₦{convertAmount(25000)}</td>
                        </tr>


                        <tr>
                            <td className='tdp'>Products & Management</td>
                            <td>Strategic Resource Planning<br/>
Project Management
Resource Allocation and Optimization,<br/>Product Management Fundamentals<br/>
Lifecycle Management<br/>
Market Analysis for Product Management</td>
                            <td>₦{convertAmount(20000)}</td>
                        </tr>



                        <tr>
                            <td className='tdp'>Pre-Degree Programme</td>
                            <td>Foundation in Law<br/>
Foundation in Business<br/>
Foundation in Management Studies</td>
                            <td>₦{convertAmount(85000)} without learning kits<br /> 
                            ₦{convertAmount(185000)}
                            + learning kits</td>
                        </tr>

                        
                        <tr>
                            <td className='tdp'>Pay Per Course</td>
                            <td>Build your personal growth just how you 
                                <br />want, choose a specific tailored course just for you.</td>
                            <td>₦{convertAmount(6500)}/Starting per course</td>
                        </tr>

                        <tr>
                            <td className='tdp'>For Organisations</td>
                            <td>Drive team productivity.</td>
                            <td>₦{convertAmount(35000)}/per team</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section>
                <Pricingplans />
            </section>

            <section>
                <PaymentPricingFaqs />
            </section>

        </div>
    );
};

export default Pricing;
