import React from 'react';
import { Routes, Route } from 'react-router-dom';

import LM from './directory/LM';
import About from './directory/About';
import Certificate from './directory/certificate';
import Programme from './directory/Programme';
import Organisations from './directory/Organisations';
import Legalcompliance from './directory/Legal-and-compliance';
import Blog from './directory/Blog';
import Corporateandbankingsector from './directory/Corporate-and-banking-sector';
import Humanresourceandproductdevelopmentstudies from './directory/Human-resource-and-product-development-studies';
import Predegree from './directory/Pre-degree';
import ProductManagement from './directory/Product-Management';
import CoomingSoon from './directory/CoomingSoon';
import Pricing from './directory/Pricing';
import Scholarship from './directory/Scholarship';
import Login from './directory/Login';
import Paypercourse from './directory/Pay-per-course';
import Admissionforpredegree from './directory/Admission-for-pre-degree';

import NotFound from './directory/NotFound';




import BusinessAdministration from './Pre-degree/Business-Administration';
import Law from './Pre-degree/Law';
import MassCommunication from './Pre-degree/Mass-Communication';
import HIR from './Pre-degree/HIR';




import Faqs from './Support/faqs';
import Guides from './Support/guides';


import Policy from './terms/policy';
import Privacy from './terms/privacy';
import Refunds from './terms/refunds';


import Learninghowtonavigatejobmarkets from './blog/Learning-how-to-navigate-job-markets';
import Stayingproductiveinaworkenvironment from './blog/Staying-productive-in-a-work-environment';
import MaximizingYourLearningExperienceTipsforOnlineStudents from './blog/Maximizing-Your-Learning-Experience-Tips-for-Online-Students';
import Balancingworkandstudy from './blog/Balancing-work-and-study';
import Careeradvancementthroughcertificationcoursesonlecturemeet from './blog/Career-advancement-through-certification-courses-on-lecturemeet';
import Thefutureofprofessionaldevelopment from './blog/The-future-of-professional-development';
import Compliancecertifications5jobrolesandbenefits from './blog/compliance-certifications-5-job-roles-and-benefits';
import Employeetrainingforteamsorgansaitions from './blog/employee-training-for-teams-organsaitions';
import Workinginthefinancialsectorasacomplianceofficer from './blog/Working-in-the-financial-sector-as-a-compliance-officer';
import Articles from './blog/Articles';




import Jobs from './career/jobs';
import Apply from './career/jobs/apply';
import JobDescription from './career/jobs/JobDescription'






function AppRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<LM />} />
      <Route path="/LM" element={<LM />} />
      <Route path="/about" element={<About />} />
      <Route path="/certificate" element={<Certificate />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/programme" element={<Programme />} />
      <Route path="/organisations" element={<Organisations />} />
      <Route path="/Admission-for-pre-degree" element={<Admissionforpredegree />} />
      <Route path="/Legal-and-compliance" element={<Legalcompliance />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Pay-per-course" element={<Paypercourse />} />
      <Route path="/Corporate-and-banking-sector" element={<Corporateandbankingsector />} />
      <Route path="/Human-resource-and-product-development-studies" element={<Humanresourceandproductdevelopmentstudies />} />
      <Route path="/Pre-degree" element={<Predegree />} />
      <Route path="/Pricing" element={<Pricing />} />
      <Route path="/Scholarship" element={<Scholarship />} />
      <Route path="/Business-Administration" element={<BusinessAdministration />} />
      <Route path="/Mass-Communication" element={<MassCommunication />} />
      <Route path="/Product-Management" element={<ProductManagement />} />
      <Route path="/Law" element={<Law />} />
      <Route path="/CoomingSoon" element={<CoomingSoon />} />
      

      <Route path="/Pre-degree/Business-Administration" element={<BusinessAdministration />} />
      <Route path="/Pre-degree/Law" element={<Law />} />
      <Route path="/Pre-degree/Mass-Communication" element={<MassCommunication />} />
      <Route path="/Pre-degree/HIR" element={<HIR />} />
      
      

      
      
      <Route path="/Support/faqs" element={<Faqs />} />
      <Route path="/Support/guides" element={<Guides />} />



      <Route path="/terms/policy" element={<Policy />} />
      <Route path="/terms/privacy" element={<Privacy />} />
      <Route path="/terms/refunds" element={<Refunds />} />


      
      <Route path="/blog/Articles" element={<Articles />} />
      <Route path="/blog/Maximizing-Your-Learning-Experience-Tips-for-Online-Students" element={<MaximizingYourLearningExperienceTipsforOnlineStudents />} />
      <Route path="/blog/Balancing-work-and-study" element={<Balancingworkandstudy />} />
      <Route path="/blog/Career-advancement-through-certification-courses-on-lecturemeet" element={<Careeradvancementthroughcertificationcoursesonlecturemeet />} />
      <Route path="/blog/The-future-of-professional-development" element={<Thefutureofprofessionaldevelopment />} />
      <Route path="/blog/compliance-certifications-5-job-roles-and-benefits" element={<Compliancecertifications5jobrolesandbenefits />} />
      <Route path="/blog/employee-training-for-teams-organsaitions" element={<Employeetrainingforteamsorgansaitions />} />
      <Route path="/blog/Working-in-the-financial-sector-as-a-compliance-officer" element={<Workinginthefinancialsectorasacomplianceofficer />} />
      <Route path="/blog/Staying-productive-in-a-work-environment" element={<Stayingproductiveinaworkenvironment />} />
      <Route path="/blog/Learning-how-to-navigate-job-markets" element={<Learninghowtonavigatejobmarkets />} />
      
      



          
      <Route path="/career/jobs" element={<Jobs />} />
      <Route path="/career/jobs/Apply/:id" element={<Apply />} />
      <Route path="/career/jobs/:id" element={<JobDescription />} />
      



      
      
      <Route path="*" element={<NotFound />} />
      
      
      
      


      </Routes>
  );
}

export default AppRoutes;
