import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import campushall from '../assets/images/campushall.avif';
import '../assets/css/Pre-degree.css';
import HIRModel from './Module/HIRModel';
import { Link } from 'react-router-dom';
import Buildlearn from '../assets/images/online.jpg';
import Notification from '../components/Notification';



const MassComm = ({ imageUrl, header, text }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/Admission-for-pre-degree'); 
  };



    const handleClickPrograms = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Mass Communication - Pre-Degree Programme | Lecturemeet </title>
        <meta name="description" content="Lecturemeet History and International Relations - Pre-Degree Programme: Prepare for your academic and professional journey in history and international studies. Enroll in our comprehensive pre-degree courses to build a solid foundation." />
        <meta name="keywords" content="Lecturemeet, History, International Relations, Pre-Degree Programme, academic preparation, professional development, education, history studies, international relations" />
        <meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Pre-degree/HIR",
        "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24"
        }
    `}
</script>
<meta property="og:title" content="History and International Relations - Pre-Degree Programme | Lecturemeet" />
        <meta property="og:description" content="Prepare for your academic and professional journey in history and international relations with Lecturemeet's Pre-Degree Programme. Enroll in our comprehensive courses to build a solid foundation." />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
<meta property="og:url" content="https://lecturemeet.com/Pre-degree/HIR" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@LectureMeet" />
        <meta name="twitter:creator" content="@LectureMeet" />

      </Helmet>


      <div className="pre-degree-program-course-hero">
            <h1 className="pre-degree-program-course-header">Pre-Degeee Course Application 
            Commences <br />in November, 2024</h1>
            <p className="pre-degree-program-course-paragraph">
                Get ready to embark on an exciting learning journey with LectureMeet! Our pre-degree programs are designed 
                to give you the foundational knowledge and skills you need to excel in your chosen field. Join us and 
                experience the joy of learning like never before.
            </p>
            <a href="https://pre-degree.lecturemeet.com/onboard" className="pre-degree-program-course-button">
                Notify Me
            </a>
        </div>




      <section className="prog-mass-comm">
      <div className="container">
        <div className="header-content">
          <h1>History and International Relations</h1>
          <p>Join other learners to embark on a learning journey into history, global studies, and diplomatic relations.</p>
            <button onClick={handleClick}>See Requirements</button>
        </div>
      </div>
    </section>



<br />

   
<section className="scroll-to-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <a href="#about-program" className="scroll-to-link" onClick={() => handleClickPrograms('about-program')}>About Program</a>
          </div>
          <div className="col-md-3">
            <a href="#admission" className="scroll-to-link" onClick={() => handleClickPrograms('admission')}>Admission & Requirements</a>
          </div>
          <div className="col-md-3">
            <a href="#fees" className="scroll-to-link" onClick={() => handleClickPrograms('fees')}>Fees</a>
          </div>
          <div className="col-md-3">
            <a href="#learning-model" className="scroll-to-link" onClick={() => handleClickPrograms('learning-model')}>Learning Model</a>
          </div>
          <div className="col-md-3">
            <a href="#scholarship" className="scroll-to-link" onClick={() => handleClickPrograms('scholarship')}>Scholarship</a>
          </div>
          <div className="col-md-3">
            <a href="#internship" className="scroll-to-link" onClick={() => handleClickPrograms('internship')}>Internship Opportunities</a>
          </div>
          <div className="col-md-3">
            <a href="#remote-job" className="scroll-to-link" onClick={() => handleClickPrograms('remote-jobs')}>Career </a>
          </div>
          <div className="col-md-3">
            <a href="#benefits" className="scroll-to-link" onClick={() => handleClickPrograms('benefits')}>Benefits</a>
          </div>
        </div>
      </div>
    </section>



    <section className="prog-190" id='about-program'>
    <div className="container">
      <div className="row">        
      <div className="col-md-6 pmsect-09">
          <img src={campushall} alt="About the Mass Commmunication Programmes" className="img-fluid" />
            </div>
        <div className="col-md-6 pmsect-09">
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: 'left', color: 'blue' }}>Diploma in History and International Relations</h1>
              <p>The History and International Relations Pre-Degree program is designed to prepare 
                students for advanced studies in history and global affairs by providing a 
                foundational understanding of these fields.
                <br />
                <br />
                The program aims to equip students with basic knowledge 
                and skills in history and international relations, covering essential concepts, 
                practices, and theories. It is structured to provide a solid foundation for 
                pursuing further education in history, international studies, or related fields.
              </p>
         
        </div>

      </div>
    </div>
  </section>







 {/* Admission & Requirements Section */}
 <section className="admission-requirements container-text" id="admission">
                <div className="container">
                    <h2>Admission & Requirements</h2>
                    <p>Find out the entry requirements and application process for Diploam or Pre-degree program in Mass Communication at Lecturemeet, you need;</p>
                    <ul>
                        <li>High School Diploma or equivalent</li>
                        <li>Must have completed secondary education (SSCE/WAEC/NECO) with a minimum of five credits, including English Language and Mathematics.</li>
                        <li>Must be a Nigerian or studied in any African country</li>
                        <li>Must be at least 16 years old at the time of application</li>
                        <li>Pass the entrance assessment by Lecturemeet</li>
                    </ul>
                </div>
            </section>

            {/* Fees Section */}
            <section className="fees container-text" id="fees">
                <div className="container">
                    <h2>Program Fees</h2>
                    <br />
                    <p>Duration: 3 months</p>
                   <ul>
                        <li>Non-Refundable Application Fee: ₦15,000</li>
                        <li>Tuition Fee: ₦185,000 <s className='text-danger'>₦245,000</s> (With learning kits and physical certificate delivery)</li>
                        <li>Tuition Fee: 85,000 <s className='text-danger'>₦130,000</s> (Without learning kits) Materials are all digital </li>
                        <br />
                        <p>Any of the fees covers academic study for the duration inclusive of the course materials</p>
                       </ul>
                   
                </div>
            </section>

        


            {/* Learning Model Section */}
            <section className="learning-model" id="learning-model">
<HIRModel />
            </section>


            {/* Scholarship Section */}
            <section className="scholarship container-text" id="scholarship">
            <div className="container">
                <h2>Scholarship Opportunities</h2>
                <p>Offering scholarships for our Mass CommunicationPre-degree program, covers tuition and learning materials for a 3-month duration with a diploma upon completion. Scholarships are currently open for only eligible applicants and are awarded based on merit and financial need.</p>
                <ul>
                    <li>Merit-based Scholarships</li>
                    <li>Need-based Financial Aid</li>
                    <li>Special Grants from support partners</li>
                </ul>
                <Link 
                    to="/Scholarship" 
                    className="btn todayforYou" 
                    style={{
                        display: 'inline-block',
                        padding: '0.5rem 1rem',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#ffffff',
                        backgroundColor: '#007bff',
                        border: 'none',
                        borderRadius: '0.25rem',
                        textDecoration: 'none',
                        textAlign: 'left',
                        marginTop: '1rem',
                        transition: 'background-color 0.3s'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                >
                    Explore Scholarship
                </Link>
            </div>
        </section>

            {/* Internship Opportunities Section */}
            <section className="internship container-text" id="internship">
                <div className="container">
                    <h2>Internship Opportunities</h2>
                    <p>Learn about internship placements and career support provided during and after the program.</p>
                    <ul>
                        <li>Industry Partnerships</li>
                        <li>Work Experience Opportunities</li>
                        <li>Career Counseling</li>
                    </ul>
                </div>
            </section>

            {/* Job Apply Section */}
            <section className="paypercourse-section" id="remote-job">
  <div className="paypercourse-container">
    <div className="per-course-left">
      <h2>Explore Career Options</h2>
      <p>Career options with a diploma degree certificate in Mass Communication
        <br />
        A diploma in Mass Communication provides a solid foundation for various legal and administrative roles, offering flexibility and a broad range of career options across different sectors.
  </p>
      <div className="per-course-grid">
      <div className="per-course-item">Journalist/Reporter</div>
        <div className="per-course-item">Public Relations Specialist</div>
        <div className="per-course-item">Media Planner</div>
        <div className="per-course-item">Broadcaster</div>
        <div className="per-course-item">On Air Personnel (OAP)</div>
        <div className="per-course-item">Media Marketing Coordinator</div>
      </div>
    </div>
    <div className="per-course-right">
      <img src={Buildlearn} alt="Career options in Mass Communications" />
    </div>
  </div>
</section>


            {/* Benefits Section */}
            <section className="benefits container-text" id="benefits">
                <div className="container">
                    <h2>Program Benefits</h2>
                    <p>Discover the benefits of enrolling in the Mass Communications pre-degree program.</p>
                    <ul>
                        <li>Internship Opportunity</li>
                        <li>Partner direct entry</li>
                        <li>Networking Opportunities</li>
                        <li>Career Advancement</li>
                    </ul>
                </div>
            </section>




            <Notification />



    </div>
  );
};

export default MassComm;
